var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c("div", {
    attrs: {
      id: "content"
    }
  }, [_vm._m(0), _c("div", {
    staticClass: "companyBlock"
  }, [_c("div", {
    staticClass: "companyBlock-about"
  }, [_c("div", {
    staticClass: "companyBlock-hd"
  }, [_vm.language == 1 ? _c("h2", [_vm._v("资质证书")]) : _vm._e(), _vm.language == 2 ? _c("h2", [_vm._v("CERTIFICATION")]) : _vm._e(), _vm.language == 3 ? _c("h2", [_vm._v("資質證書")]) : _vm._e()]), _c("ul", {
    staticClass: "qulificationCertificate"
  }, _vm._l(_vm.certificate, function (items, index) {
    return _c("li", [_c("img", {
      attrs: {
        src: _vm.title + items.cerPic
      }
    }), _vm.language == 1 ? _c("p", [_vm._v(_vm._s(items.cerName))]) : _vm._e(), _vm.language == 2 ? _c("p", [_vm._v(_vm._s(items.enCerName))]) : _vm._e(), _vm.language == 3 ? _c("p", [_vm._v(_vm._s(items.trCerName))]) : _vm._e(), _vm.language == 1 ? _c("p", [_vm._v(_vm._s(items.cerNo))]) : _vm._e(), _vm.language == 2 ? _c("p", [_vm._v(_vm._s(items.enCerNo))]) : _vm._e(), _vm.language == 3 ? _c("p", [_vm._v(_vm._s(items.trCerNo))]) : _vm._e()]);
  }), 0)])])]);
};

var staticRenderFns = [function () {
  var _vm = this,
      _c = _vm._self._c;

  return _c("div", {
    staticClass: "aboutBannerBox"
  }, [_c("img", {
    staticClass: "aboutBanner",
    attrs: {
      src: require("../assets/banner/qualification.jpg")
    }
  })]);
}];
render._withStripped = true;
export { render, staticRenderFns };